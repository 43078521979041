var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
      [
        _c(
          "c-table",
          {
            ref: "table",
            attrs: {
              title: _vm.tableTitle,
              columns: _vm.grid.columns,
              rowKey: "dataId",
              data: _vm.grid.data,
              editable: _vm.editable,
            },
            on: {
              getTableHeight: (height) => {
                this.imgHeight = height
              },
            },
            scopedSlots: _vm._u([
              {
                key: "customArea",
                fn: function ({ props }) {
                  return [
                    _c("q-btn", {
                      attrs: {
                        round: "",
                        unelevated: "",
                        size: "6px",
                        color: "amber",
                        icon: "search",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.preview(props.row, props.pageIndex)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          },
          [
            _c(
              "template",
              { slot: "table-button" },
              [
                _c(
                  "q-btn-group",
                  { attrs: { outline: "" } },
                  [
                    _vm.editable
                      ? _c("c-btn", {
                          attrs: { label: "추가", icon: "add" },
                          on: { btnClicked: _vm.add },
                        })
                      : _vm._e(),
                    _c("c-btn", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.editable && _vm.grid.data.length > 0,
                          expression: "editable && grid.data.length > 0",
                        },
                      ],
                      attrs: {
                        url: _vm.insertUrl,
                        isSubmit: _vm.isSave,
                        param: _vm.grid.data,
                        mappingType: "POST",
                        label: "저장",
                        icon: "save",
                      },
                      on: {
                        beforeAction: _vm.saveItem,
                        btnCallback: _vm.saveCallback,
                      },
                    }),
                    _c("c-btn", {
                      attrs: { label: "검색", icon: "search" },
                      on: { btnClicked: _vm.getList },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }